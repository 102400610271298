import styled from '@emotion/styled';
import { Col, Row, Pagination as $Pagination } from 'antd';
import React, { useCallback, memo } from 'react';
import { isNil } from 'lodash';

import { getPageOffset } from '../../utils/table';
import theme from '../../theme';

export type PaginationProps = {
  current?: number;
  pageSize?: number;
  total: number;
  reset?: () => void;
  onChange?: (event: PageChangeEvent) => void;
  isCheckBoxCount?: boolean;
  checkboxCount?: number
};

export type PageChangeEvent = {
  current: number;
  offset: number;
  pageSize: number;
};

const CustomPagination = styled($Pagination)`
  color: ${theme.colorsBlack};
  margin-left: 15px;
  .ant-pagination-next,
  .ant-pagination-prev {
    .ant-pagination-item-link {
      background-color: transparent;
    }
  }
`;

const PageSpan = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: ${theme.colorsBlack};
`;

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_CURRENT = 1;

export const Pagination = memo(function Pagination({
  current = DEFAULT_CURRENT,
  pageSize = DEFAULT_PAGE_SIZE,
  total,
  onChange,
  isCheckBoxCount = false,
  checkboxCount = 0
}: PaginationProps) {
  const offset = getPageOffset(pageSize, current);
  const start = total > 0 ? offset + 1 : 0;
  const end = Math.min(total, offset + pageSize);

  const handleChange = useCallback(
    (page: number, pageSize?: number) => {
      if (isNil(pageSize)) {
        return;
      }

      const offset = getPageOffset(pageSize, page);

      if (onChange) {
        onChange({ current: page, offset, pageSize });
      }
    },
    [onChange]
  );

  return (
    <>
      {total > 0 && (
        <Row align="middle" justify="end">
          <Col>
            <PageSpan>
              {`${start} - ${end} out of ${total}`}
              {isCheckBoxCount && checkboxCount >= 1 ? ` (${checkboxCount} selected)` : ''}
            </PageSpan>
          </Col>
          {total > pageSize && (
            <Col>
              <CustomPagination total={total} current={current} pageSize={pageSize} simple onChange={handleChange} />
            </Col>
          )}
        </Row>
      )}
    </>
  );
});
